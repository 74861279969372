import { Container } from "../Container";

export const Footer = (props) => {
  const options = {
    block: "start",
    behavior: "smooth",
  };
  const handleNavLinkClick = (e) => {
    document.querySelector(`.${e.target.dataset.selector}`).scrollIntoView(options);
  };
  return (
    <footer className="footer">
      <Container>
        <div className="footer__inner">
          <div className="footer__contact">
            <div className="footer__contact__column">
              <div className="footer__contact__column__title">
                Address
                <br /> Information
              </div>
              <div className="footer__contact__column__line">
                <svg
                  className="footer__contact__column__line__icon"
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.29635 14.4043C2.74673 13.9617 0 10.9337 0 7.26419C0 3.29004 3.22168 0.0683594 7.19583 0.0683594C11.17 0.0683594 14.3917 3.29004 14.3917 7.26419C14.3917 10.9337 11.6449 13.9617 8.09531 14.4043V17.1585H6.29635V14.4043ZM0.899479 18.0579H13.4922V19.8569H0.899479V18.0579Z"
                    fill="#171717"
                  />
                </svg>
                <div className="footer__contact__column__line__text">
                  14772172, Harju maakond, Tallinn,
                  <br />
                  Kesklinna linnaosa, <br />
                  Tornimäe tn 5, 10145
                </div>
              </div>
            </div>
            <div className="footer__contact__column">
              <div className="footer__contact__column__title">
                Contact
                <br /> Information
              </div>
              <div className="footer__contact__column__line">
                <svg
                  className="footer__contact__column__line__icon"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9896 7.09518C18.9831 7.09518 19.7885 7.9006 19.7885 8.89414V12.4921C19.7885 13.4856 18.9831 14.291 17.9896 14.291H17.0344C16.5918 17.8406 13.5638 20.5874 9.89427 20.5874V18.7884C12.8749 18.7884 15.2911 16.3721 15.2911 13.3915V7.99466C15.2911 5.01405 12.8749 2.59779 9.89427 2.59779C6.91366 2.59779 4.4974 5.01405 4.4974 7.99466V14.291H1.79896C0.805421 14.291 0 13.4856 0 12.4921V8.89414C0 7.9006 0.805421 7.09518 1.79896 7.09518H2.75411C3.19674 3.54556 6.22474 0.798828 9.89427 0.798828C13.5638 0.798828 16.5918 3.54556 17.0344 7.09518H17.9896ZM6.07998 14.0975L7.03355 12.5718C7.8629 13.0913 8.8435 13.3915 9.89427 13.3915C10.945 13.3915 11.9257 13.0913 12.755 12.5718L13.7086 14.0975C12.6028 14.7901 11.2953 15.1905 9.89427 15.1905C8.49324 15.1905 7.18578 14.7901 6.07998 14.0975Z"
                    fill="#171717"
                  />
                </svg>

                <a href="tel:+19162385559" className="footer__contact__column__line__text">
                  +1 (916) 238 5559
                </a>
              </div>
              <div className="footer__contact__column__line">
                <svg
                  className="footer__contact__column__line__icon"
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.79889 0.666992H17.9895C18.4863 0.666992 18.889 1.06971 18.889 1.56647V15.9581C18.889 16.4549 18.4863 16.8576 17.9895 16.8576H1.79889C1.30213 16.8576 0.899414 16.4549 0.899414 15.9581V1.56647C0.899414 1.06971 1.30213 0.666992 1.79889 0.666992ZM9.94871 8.47708L4.18001 3.57924L3.01569 4.95058L9.95996 10.8465L16.7792 4.94506L15.6019 3.58476L9.94871 8.47708Z"
                    fill="#171717"
                  />
                </svg>

                <a href="mailto:technohorizonino@proton.me" className="footer__contact__column__line__text">
                  technohorizonino@proton.me
                </a>
              </div>
            </div>
          </div>
          <div className="footer__row">
            <div className="footer__row__left">
              <div className="footer__row__logo__container">
                <svg
                  className="footer__row__logo"
                  width="80"
                  height="77"
                  viewBox="0 0 80 77"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.6965 24.8379C77.8592 21.6795 75.5846 18.7001 72.8736 15.9891C56.3109 -0.5736 29.7315 -0.847615 13.5068 15.3771C-2.71787 31.6018 -2.44386 58.1812 14.1188 74.7439C14.5525 75.1775 14.993 75.6 15.4399 76.0112C6.0181 68.2069 0 56.3133 0 42.9899C0 19.4835 18.7328 0.427734 41.8408 0.427734C58.566 0.427734 72.9992 10.4104 79.6965 24.8379Z"
                    fill="url(#paint0_linear_43_392)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.0291 27.8408C43.4841 27.8408 44.6637 29.0204 44.6637 30.4755C44.6637 31.9306 43.4841 33.1101 42.0291 33.1101L34.1251 33.1101V42.3321L56.5197 42.3321C57.9747 42.3321 59.1543 43.5117 59.1543 44.9667C59.1543 46.4218 57.9747 47.6014 56.5197 47.6014H34.1251V57.466C34.1251 57.8379 33.8237 58.1393 33.4518 58.1393H29.5291C29.1573 58.1393 28.8558 57.8379 28.8558 57.466V44.9667V33.1101L22.2692 33.1101C20.8141 33.1101 19.6345 31.9306 19.6345 30.4755C19.6345 29.0204 20.8141 27.8408 22.2692 27.8408L42.0291 27.8408Z"
                    fill="url(#paint1_linear_43_392)"
                  />
                  <path
                    d="M51.2504 28.5141C51.2504 28.1423 51.5518 27.8408 51.9237 27.8408H58.481C58.8529 27.8408 59.1543 28.1423 59.1543 28.5141V54.1873C59.1543 56.37 57.3849 58.1393 55.2023 58.1393C53.0197 58.1393 51.2504 56.37 51.2504 54.1873V28.5141Z"
                    fill="url(#paint2_linear_43_392)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_43_392"
                      x1="41.8408"
                      y1="0.427734"
                      x2="41.9474"
                      y2="51.6501"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2ACED7" />
                      <stop offset="1" stopColor="#6E06FE" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_43_392"
                      x1="39.3944"
                      y1="27.8408"
                      x2="39.3944"
                      y2="68.0193"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2ACAD3" />
                      <stop offset="1" stopColor="#6C0BFC" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_43_392"
                      x1="55.2023"
                      y1="27.8408"
                      x2="55.2023"
                      y2="58.1393"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset="0.0001" stopColor="#7000FF" />
                      <stop offset="1" stopColor="#27BCC5" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="footer__row__left__title">
                  Techno
                  <br /> horizon
                </div>
              </div>
              <div className="footer__row__left__copyright">Techno Horizon © 2023, all rights reserved</div>
            </div>
            <div className="footer__row__column">
              <div className="footer__row__item" data-selector="dev" onClick={handleNavLinkClick}>
                Web and Mobile development
              </div>
              <div className="footer__row__item" data-selector="design" onClick={handleNavLinkClick}>
                Web and Ux/Ui design
              </div>
              <div className="footer__row__item" data-selector="marketing" onClick={handleNavLinkClick}>
                Marketing and SEO
              </div>
              <div className="footer__row__item" data-selector="support" onClick={handleNavLinkClick}>
                Support & Maintenance
              </div>
            </div>
            <div className="footer__row__column">
              <div className="footer__row__item" data-selector="extra" onClick={handleNavLinkClick}>
                Other Services
              </div>
              <div className="footer__row__item" onClick={props.handleFooterPrivacyLinkClick}>
                Privacy Policy
              </div>
            </div>
            <div className="footer__row__right">
              <div className="footer__row__right__text">Designed by</div>
              <svg width="241" height="46" viewBox="0 0 241 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M30.9274 37H53V29.5772H38.358V25.2377H53V6.04771L26.5834 6.04771V0L19.1528 0V6.05247L0 6.05247L0 13.4753H0.0523954L19.0289 36.9976L19.1433 36.8834V37H26.5643L26.5643 13.4753H45.5504V17.8148L30.9274 17.8148L30.9274 37ZM19.1433 25.4518L9.37872 13.4753L19.1433 13.4753V25.4518Z"
                  fill="white"
                />
                <path d="M73.0881 5.33758V33.6051H87.2049V36.5478H70V5.33758H73.0881Z" fill="white" />
                <path
                  d="M100.256 13.7197C103.55 13.7197 106.182 14.8938 108.153 17.242C110.182 19.5605 111.197 22.31 111.197 25.4904C111.197 25.6391 111.167 26.0998 111.109 26.8726H91.7419C92.036 29.1019 92.9771 30.8854 94.5653 32.2229C96.1534 33.5605 98.1386 34.2293 100.521 34.2293C103.874 34.2293 106.241 32.966 107.623 30.4395L110.182 31.9554C109.212 33.5605 107.873 34.8238 106.168 35.7452C104.491 36.6369 102.594 37.0828 100.477 37.0828C97.0063 37.0828 94.183 35.983 92.0066 33.7834C89.8303 31.5839 88.7421 28.7898 88.7421 25.4013C88.7421 22.0425 89.8156 19.2633 91.9625 17.0637C94.1094 14.8344 96.874 13.7197 100.256 13.7197ZM100.256 16.5732C97.9622 16.5732 96.0358 17.2569 94.4771 18.6242C92.9477 19.9915 92.036 21.7898 91.7419 24.0191H108.197C107.873 21.6412 106.976 19.8132 105.506 18.535C103.977 17.2272 102.227 16.5732 100.256 16.5732Z"
                  fill="white"
                />
                <path
                  d="M134.798 18.6242V14.2548H137.709V35.656C137.709 38.8662 136.621 41.3928 134.445 43.2357C132.268 45.0786 129.666 46 126.636 46C121.49 46 118.005 44.1868 116.181 40.5605L118.74 39.0892C120.063 41.7941 122.695 43.1465 126.636 43.1465C129.166 43.1465 131.151 42.4777 132.592 41.1401C134.062 39.8025 134.798 37.9745 134.798 35.656V32.1783C132.768 35.448 129.798 37.0828 125.886 37.0828C122.74 37.0828 120.049 35.9533 117.813 33.6943C115.608 31.4352 114.505 28.6709 114.505 25.4013C114.505 22.1316 115.608 19.3673 117.813 17.1083C120.049 14.8493 122.74 13.7197 125.886 13.7197C129.798 13.7197 132.768 15.3546 134.798 18.6242ZM119.931 31.6879C121.607 33.3822 123.666 34.2293 126.107 34.2293C128.548 34.2293 130.607 33.3822 132.283 31.6879C133.96 29.9342 134.798 27.8386 134.798 25.4013C134.798 22.9342 133.96 20.8535 132.283 19.1592C130.607 17.4352 128.548 16.5732 126.107 16.5732C123.666 16.5732 121.607 17.4352 119.931 19.1592C118.254 20.8535 117.416 22.9342 117.416 25.4013C117.416 27.8386 118.254 29.9342 119.931 31.6879Z"
                  fill="white"
                />
                <path
                  d="M162.844 18.6242V14.2548H165.755V36.5478H162.844V32.1783C160.814 35.448 157.844 37.0828 153.932 37.0828C150.785 37.0828 148.094 35.9533 145.859 33.6943C143.654 31.4352 142.551 28.6709 142.551 25.4013C142.551 22.1316 143.654 19.3673 145.859 17.1083C148.094 14.8493 150.785 13.7197 153.932 13.7197C157.844 13.7197 160.814 15.3546 162.844 18.6242ZM147.977 31.6879C149.653 33.3822 151.712 34.2293 154.153 34.2293C156.594 34.2293 158.653 33.3822 160.329 31.6879C162.005 29.9342 162.844 27.8386 162.844 25.4013C162.844 22.9342 162.005 20.8535 160.329 19.1592C158.653 17.4352 156.594 16.5732 154.153 16.5732C151.712 16.5732 149.653 17.4352 147.977 19.1592C146.3 20.8535 145.462 22.9342 145.462 25.4013C145.462 27.8386 146.3 29.9342 147.977 31.6879Z"
                  fill="white"
                />
                <path d="M174.832 36.5478H171.92V4H174.832V36.5478Z" fill="white" />
                <path
                  d="M192.48 5.33758H203.509C206.273 5.33758 208.582 6.27388 210.435 8.1465C212.288 10.0191 213.214 12.3524 213.214 15.1465C213.214 17.9406 212.288 20.2739 210.435 22.1465C208.582 24.0191 206.273 24.9554 203.509 24.9554H195.568V36.5478H192.48V5.33758ZM195.568 22.0127H203.509C205.421 22.0127 206.994 21.3737 208.229 20.0955C209.494 18.8174 210.126 17.1677 210.126 15.1465C210.126 13.155 209.494 11.5202 208.229 10.242C206.994 8.93418 205.421 8.28026 203.509 8.28026H195.568V22.0127Z"
                  fill="white"
                />
                <path
                  d="M241 36.5478H237.471L230.236 24.0637H221.589V36.5478H218.501V5.33758H230.853C233.383 5.33758 235.559 6.25902 237.383 8.10191C239.206 9.9448 240.118 12.1444 240.118 14.7006C240.118 16.8408 239.485 18.7282 238.221 20.3631C237.015 21.9682 235.427 23.0679 233.456 23.6624L241 36.5478ZM230.853 8.28026H221.589V21.121H230.853C232.559 21.121 234.015 20.4968 235.221 19.2484C236.427 17.9406 237.03 16.4246 237.03 14.7006C237.03 12.9469 236.427 11.431 235.221 10.1529C234.015 8.90446 232.559 8.28026 230.853 8.28026Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};
