import { useInView } from "react-intersection-observer";
import { Container } from "../Container";
import classNames from "classnames";
import { Typewriter } from "../Typewriter";

export const Banner = () => {
  const observer = useInView({ threshold: 0.1 });
  return (
    <Container>
      <section className="banner" ref={observer.ref}>
        <div className="banner__info">
          <h1 className="banner__title">
            <svg
              className="banner__title__icon"
              width="120"
              height="115"
              viewBox="0 0 120 115"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M120 37.2175C117.233 32.4612 113.808 27.9745 109.726 23.892C84.7872 -1.04646 44.7666 -1.45904 20.3371 22.9704C-4.09241 47.3999 -3.67982 87.4206 21.2586 112.359C21.9102 113.011 22.5721 113.645 23.2438 114.264C9.05968 102.513 0 84.6059 0 64.5468C0 29.1532 28.2059 0.460968 62.9996 0.460968C88.1837 0.460968 109.916 15.4928 120 37.2175Z"
                fill="url(#paint0_linear_202_4)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.283 41.7366C65.4739 41.7366 67.25 43.5127 67.25 45.7036C67.25 47.8945 65.4739 49.6706 63.283 49.6706L51.382 49.6706V63.5551L85.1015 63.5551C87.2924 63.5551 89.0685 65.3312 89.0685 67.5221C89.0685 69.713 87.2924 71.4891 85.1015 71.4891L51.382 71.4891V86.357C51.382 86.9093 50.9343 87.357 50.382 87.357H44.448C43.8957 87.357 43.448 86.9093 43.448 86.357V67.5289C43.448 67.5266 43.448 67.5243 43.448 67.5221C43.448 67.5198 43.448 67.5175 43.448 67.5153V49.6706L33.5306 49.6706C31.3397 49.6706 29.5636 47.8945 29.5636 45.7036C29.5636 43.5127 31.3397 41.7366 33.5306 41.7366L47.415 41.7366H63.283Z"
                fill="url(#paint1_linear_202_4)"
              />
              <path
                d="M77.1675 42.7366C77.1675 42.1843 77.6152 41.7366 78.1675 41.7366H88.0685C88.6207 41.7366 89.0685 42.1843 89.0685 42.7366V81.4066C89.0685 84.6929 86.4043 87.357 83.118 87.357C79.8316 87.357 77.1675 84.6929 77.1675 81.4066V42.7366Z"
                fill="url(#paint2_linear_202_4)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_202_4"
                  x1="62.9997"
                  y1="0.460968"
                  x2="63.1601"
                  y2="77.5809"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2ACED7" />
                  <stop offset="1" stopColor="#6E06FE" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_202_4"
                  x1="59.316"
                  y1="41.7366"
                  x2="59.316"
                  y2="102.233"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2ACAD3" />
                  <stop offset="1" stopColor="#6C0BFC" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_202_4"
                  x1="83.118"
                  y1="41.7366"
                  x2="83.118"
                  y2="87.357"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset="0.0001" stopColor="#7000FF" />
                  <stop offset="1" stopColor="#27BCC5" />
                </linearGradient>
              </defs>
            </svg>
            <p className={classNames("word", { animate: observer.inView })}>
              <Typewriter targetText="Techo" timeout={300} started={observer.inView} />
            </p>
            <p className={classNames("word", { animate: observer.inView })}>
              <Typewriter targetText="Horizon" timeout={800} started={observer.inView} />
            </p>
          </h1>
          <p className={classNames("banner__right__text", { animate: observer.inView })}>
            Techno Horizon offers a complete range of web development services, spanning from the initial concept to the
            deployment of a fully functional application.
          </p>
          <div
            className={classNames("banner__right__button__container", { animate: observer.inView })}
            onClick={() => document.querySelector(".contact").scrollIntoView({ block: "start", behavior: "smooth" })}
          >
            <svg
              className="banner__right__button"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="35" cy="35" r="35" fill="url(#paint0_linear_204_9)" />
              <path
                d="M44.8182 30.6364C46.0232 30.6364 47 31.6132 47 32.8182V37.1818C47 38.3868 46.0232 39.3636 44.8182 39.3636H43.6597C43.1229 43.6687 39.4505 47 35 47V44.8182C38.6149 44.8182 41.5455 41.8877 41.5455 38.2727V31.7273C41.5455 28.1123 38.6149 25.1818 35 25.1818C31.385 25.1818 28.4545 28.1123 28.4545 31.7273V39.3636H25.1818C23.9768 39.3636 23 38.3868 23 37.1818V32.8182C23 31.6132 23.9768 30.6364 25.1818 30.6364H26.3402C26.8771 26.3313 30.5495 23 35 23C39.4505 23 43.1229 26.3313 43.6597 30.6364H44.8182ZM30.3739 39.129L31.5305 37.2786C32.5363 37.9086 33.7256 38.2727 35 38.2727C36.2744 38.2727 37.4637 37.9086 38.4695 37.2786L39.6261 39.129C38.2849 39.969 36.6992 40.4545 35 40.4545C33.3008 40.4545 31.7151 39.969 30.3739 39.129Z"
                fill="white"
              />
              <defs>
                <linearGradient id="paint0_linear_204_9" x1="35" y1="0" x2="35" y2="70" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#6914F9" />
                  <stop offset="1" stopColor="#30BFDB" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="banner__bg" alt="banner__bg"></div>
      </section>
    </Container>
  );
};
