import { useRef } from "react";
import { Container } from "../Container";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import { Typewriter } from "../Typewriter";

export const Contact = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [company, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [statusText, setStatusText] = useState("");
  const form = useRef();
  const observer = useInView({ threshold: 0.1 });
  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    // Validate name
    if (first_name.trim() === "") {
      newErrors.first_name = "Name is required";
    }

    // Validate phone
    if (email.trim() === "") {
      newErrors.email = "Enter you email";
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email.trim())) {
      newErrors.email = "Wrong mail format";
    }

    // Validate message
    if (message.trim() === "") {
      newErrors.message = "Enter your message";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStatusText("Your message has been sent!");
          setTimeout(() => {
            setStatusText("");
          }, 2000);
        },
        (error) => {
          setStatusText("An error occured");
          setTimeout(() => {
            setStatusText("");
          }, 2000);
        }
      );
    form.current.reset();
  };
  return (
    <form className="contact" ref={form}>
      <Container>
        <div className="contact__upper" ref={observer.ref}>
          <div className={classNames("contact__upper__title", { animate: observer.inView })}>
            <svg
              className={classNames("contact__upper__title__icon", { animate: observer.inView })}
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M47.2727 16.5455C49.8836 16.5455 52 18.6619 52 21.2727V30.7273C52 33.3381 49.8836 35.4545 47.2727 35.4545H44.7628C43.5996 44.7822 35.6427 52 26 52V47.2727C33.8324 47.2727 40.1818 40.9233 40.1818 33.0909V18.9091C40.1818 11.0767 33.8324 4.72727 26 4.72727C18.1676 4.72727 11.8182 11.0767 11.8182 18.9091V35.4545H4.72727C2.11647 35.4545 0 33.3381 0 30.7273V21.2727C0 18.6619 2.11647 16.5455 4.72727 16.5455H7.23719C8.40034 7.21781 16.3573 0 26 0C35.6427 0 43.5996 7.21781 44.7628 16.5455H47.2727ZM15.9769 34.9461L18.4826 30.9369C20.662 32.3019 23.2388 33.0909 26 33.0909C28.7612 33.0909 31.338 32.3019 33.5173 30.9369L36.0232 34.9461C33.1174 36.7661 29.6816 37.8182 26 37.8182C22.3184 37.8182 18.8827 36.7661 15.9769 34.9461Z"
                fill="white"
              />
            </svg>

            <Typewriter targetText="let's get in" timeout={100} started={observer.inView} />
            <br />
            <Typewriter targetText="touch" timeout={800} started={observer.inView} />
          </div>
          <div className={classNames("contact__upper__subtitle", { animate: observer.inView })}>
            Leave your contact information so we
            <br /> can contact you
          </div>
        </div>
        <div className="contact__form">
          <div className="contact__form__column">
            <div className="contact__form__column__input__block">
              <div className="contact__form__column__input__block__title">First Name</div>
              <input
                placeholder="John"
                type="text"
                name="first_name"
                className="contact__form__column__input__block__input"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    first_name: "",
                  }));
                }}
                style={{ boxShadow: errors.first_name && "0 0 0 3px rgb(255, 59, 59)" }}
                value={first_name}
              />
            </div>
            <div className="contact__form__column__input__block">
              <div className="contact__form__column__input__block__title">Second Name</div>
              <input
                placeholder="Smith"
                type="text"
                name="last_name"
                className="contact__form__column__input__block__input"
                onChange={(e) => {
                  setLastName(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    last_name: "",
                  }));
                }}
                style={{ boxShadow: errors.last_name && "0 0 0 3px rgb(255, 59, 59)" }}
                value={last_name}
              />
            </div>
          </div>
          <div className="contact__form__column">
            <div className="contact__form__column__input__block">
              <div className="contact__form__column__input__block__title">Your company</div>
              <input
                placeholder="NexoExample Ltd"
                type="text"
                name="company"
                className="contact__form__column__input__block__input"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    company: "",
                  }));
                }}
                style={{ boxShadow: errors.company && "0 0 0 3px rgb(255, 59, 59)" }}
                value={company}
              />
            </div>
            <div className="contact__form__column__input__block">
              <div className="contact__form__column__input__block__title">Your mail</div>
              <input
                placeholder="example@mail.com"
                type="email"
                name="email"
                className="contact__form__column__input__block__input"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "",
                  }));
                }}
                style={{ boxShadow: errors.email && "0 0 0 3px rgb(255, 59, 59)" }}
                value={email}
              />
            </div>
          </div>
          <div className="contact__form__textarea__container">
            <div className="contact__form__column__input__block__title">Tell us what interests you</div>
            <textarea
              placeholder="Tell us what interests you:"
              name="message"
              className="contact__form__textarea"
              onChange={(e) => {
                setMessage(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  message: "",
                }));
              }}
              style={{ boxShadow: errors.message && "0 0 0 3px rgb(255, 59, 59)" }}
              value={message}
            />
          </div>
        </div>
        <button className="contact__button__container" onClick={handleSubmit}>
          <svg
            className="contact__button"
            width="54"
            height="55"
            viewBox="0 0 54 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.4968 21.6547C3.3285 21.2653 3.31929 20.637 4.52083 20.2364L47.1939 6.01213C48.3753 5.61827 49.0529 6.27964 48.722 7.43841L36.5298 50.1114C36.1922 51.2931 35.5109 51.334 35.0117 50.2107L26.9753 32.1287L40.3901 14.2422L22.5037 27.6571L4.4968 21.6547Z"
              fill="var(--black)"
            />
          </svg>
        </button>
        <p className="contact__form__status">{statusText}</p>
      </Container>
    </form>
  );
};
