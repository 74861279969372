import { useState } from "react";
import { Container } from "../Container";
import classNames from "classnames";

export const Header = () => {
  const [isMenuVisible, toggleMenuVisibility] = useState(false);
  const options = {
    block: "start",
    behavior: "smooth",
  };
  const handleNavLinkClick = (e) => {
    document.querySelector(`.${e.target.dataset.selector}`).scrollIntoView(options);
    toggleMenuVisibility(false);
  };
  return (
    <>
      <header className="header">
        <Container>
          <div className="header__inner">
            <svg
              onClick={() => {
                window.location.href = "/";
              }}
              width="56"
              height="53"
              viewBox="0 0 56 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.2384 16.9198C53.9649 14.7304 52.3882 12.665 50.5089 10.7858C39.0292 -0.6939 20.607 -0.88382 9.36157 10.3616C-1.88381 21.6069 -1.6939 40.0292 9.78577 51.5089C10.0857 51.8088 10.3904 52.1011 10.6996 52.3856C4.17036 46.9764 0 38.7336 0 29.5C0 13.2076 12.9837 0 29 0C40.5927 0 50.5967 6.91947 55.2384 16.9198Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.1304 19C30.139 19 30.9565 19.8176 30.9565 20.8261C30.9565 21.8346 30.139 22.6522 29.1304 22.6522L23.6522 22.6522V29.0435L39.1739 29.0435C40.1824 29.0435 41 29.861 41 30.8696C41 31.8781 40.1824 32.6957 39.1739 32.6957L23.6522 32.6957V39.5333C23.6522 39.7911 23.4432 40 23.1855 40H20.4667C20.2089 40 20 39.7911 20 39.5333V30.8696V22.6522L15.4348 22.6522C14.4263 22.6522 13.6087 21.8346 13.6087 20.8261C13.6087 19.8176 14.4263 19 15.4348 19H21.8261H29.1304Z"
                fill="black"
              />
              <path
                d="M35.5217 19.4667C35.5217 19.2089 35.7307 19 35.9884 19H40.5333C40.7911 19 41 19.2089 41 19.4667V37.2609C41 38.7736 39.7736 40 38.2609 40C36.7481 40 35.5217 38.7736 35.5217 37.2609V19.4667Z"
                fill="black"
              />
            </svg>

            <div className="header__menu" onClick={() => toggleMenuVisibility(!isMenuVisible)}>
              <div className="header__menu__line"></div>
              <div className="header__menu__line"></div>
              <div className="header__menu__line"></div>
            </div>
          </div>
        </Container>
      </header>
      <div className={classNames("menu", { menuActive: isMenuVisible })}>
        <div className="menu__burger" onClick={() => toggleMenuVisibility(!isMenuVisible)}>
          <div className="menu__burger__line"></div>
          <div className="menu__burger__line"></div>
          <div className="menu__burger__line"></div>
        </div>
        <div className="menu__services">
          <div className="menu__services__title">
            Technology
            <br /> services
          </div>
          <div className="menu__services__list">
            <div className="menu__services__list__column">
              <div className="menu__services__list__column__item" data-selector="dev" onClick={handleNavLinkClick}>
                Web and Mobile <br /> development
              </div>
              <div className="menu__services__list__column__item" data-selector="design" onClick={handleNavLinkClick}>
                Web and UX/Ui <br /> design
              </div>
              <div className="menu__services__list__column__item" data-selector="extra" onClick={handleNavLinkClick}>
                Other <br /> Services
              </div>
            </div>
            <div className="menu__services__list__column">
              <div
                className="menu__services__list__column__item"
                data-selector="marketing"
                onClick={handleNavLinkClick}
              >
                Marketing <br /> and SEO
              </div>
              <div className="menu__services__list__column__item" data-selector="support" onClick={handleNavLinkClick}>
                Support & <br /> Maintenance
              </div>
            </div>
          </div>
        </div>
        <div className="menu__contacts">
          <div className="menu__contacts__title">Contacts</div>
          <div className="menu__contacts__line">
            <svg
              className="menu__contacts__line__icon"
              width="15"
              height="21"
              viewBox="0 0 15 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.29635 14.9043C2.74673 14.4617 0 11.4337 0 7.76419C0 3.79004 3.22168 0.568359 7.19583 0.568359C11.17 0.568359 14.3917 3.79004 14.3917 7.76419C14.3917 11.4337 11.6449 14.4617 8.09531 14.9043V17.6585H6.29635V14.9043ZM0.899479 18.5579H13.4922V20.3569H0.899479V18.5579Z"
                fill="var(--black)"
              />
            </svg>
            <div className="menu__contacts__line__text">
              14772172, Harju maakond, Tallinn,
              <br />
              Kesklinna linnaosa, <br />
              Tornimäe tn 5, 10145
            </div>
          </div>
          <div className="menu__contacts__line">
            <svg
              className="menu__contacts__line__icon"
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.3 0H24.7C25.418 0 26 0.59696 26 1.33333V22.6667C26 23.4031 25.418 24 24.7 24H1.3C0.582036 24 0 23.4031 0 22.6667V1.33333C0 0.59696 0.582036 0 1.3 0ZM13.0788 11.5772L4.74139 4.31693L3.05861 6.34973L13.095 15.0895L22.9507 6.34155L21.2493 4.32512L13.0788 11.5772Z"
                fill="var(--black)"
              />
            </svg>

            <a href="mailto:technohorizonino@proton.me" className="menu__contacts__line__text">
              technohorizonino@proton.me
            </a>
          </div>
          <div className="menu__contacts__line">
            <svg
              className="menu__contacts__line__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
            >
              <path
                d="M26.3636 9.22727C27.8197 9.22727 29 10.4076 29 11.8636V17.1364C29 18.5924 27.8197 19.7727 26.3636 19.7727H24.9639C24.3152 24.9747 19.8777 29 14.5 29V26.3636C18.8681 26.3636 22.4091 22.8226 22.4091 18.4545V10.5455C22.4091 6.17738 18.8681 2.63636 14.5 2.63636C10.1319 2.63636 6.59091 6.17738 6.59091 10.5455V19.7727H2.63636C1.18034 19.7727 0 18.5924 0 17.1364V11.8636C0 10.4076 1.18034 9.22727 2.63636 9.22727H4.03613C4.68481 4.02532 9.12232 0 14.5 0C19.8777 0 24.3152 4.02532 24.9639 9.22727H26.3636ZM8.91017 19.4892L10.3076 17.2533C11.523 18.0145 12.9601 18.4545 14.5 18.4545C16.0399 18.4545 17.477 18.0145 18.6923 17.2533L20.0899 19.4892C18.4693 20.5042 16.5532 21.0909 14.5 21.0909C12.4468 21.0909 10.5307 20.5042 8.91017 19.4892Z"
                fill="var(--black)"
              />
            </svg>

            <a href="tel:+19162385559" className="menu__contacts__line__text">
              +1 (916) 238 5559
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
